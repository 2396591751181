html,
body {
  font-family: Arial, Helvetica, sans-serif;
  margin: 0;
  padding: 0;
  overflow: hidden;
  background-color: black;
}
.loading-background {
  background-color: black;
  height: 100vh;
}
.loading {
  display: inline-block;
  width: 50px;
  height: 50px;
  border: 3px solid rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  border-top-color: #fff;
  animation: spin 1s ease-in-out infinite;
  -webkit-animation: spin 1s ease-in-out infinite;
  position: fixed;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

@keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
  }
}
@-webkit-keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
  }
}
a:hover {
  color: white;
  text-decoration: none;
}

.scroll-container {
  height: 100vh;
  overflow-y: scroll;
  scroll-behavior: smooth;
  visibility: hidden;
}

.home,
.about,
.timeline {
  color: white;
  display: flex;
  flex-direction: column;
}

.home {
  height: 100vh;
}

.background {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: left top;
  background-image: url("../assets/cover.jpeg") !important;
  height: 100vh;
  width: 100vw;
  z-index: -1;
  --webkit-background-size: cover;
}

.mask {
  position: absolute;
  left: 0;
  height: 100vh;
  width: 100vw;
  pointer-events: none;
}

.mask circle {
  r: 100%;
  animation: spotlight 0.5s ease 1;
  animation-fill-mode: forwards;
  animation-delay: 500ms;
  cx: calc(10vw + 32px);
  cy: 40%;
}

@keyframes spotlight {
  to {
    r: 0;
  }
}

.intro {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  max-width: 512px;
  margin: auto;
  text-align: left;
}

.intro-content {
  pointer-events: none;
  position: absolute;
  top: 80px;
  left: 0;
  border-radius: 8px;
  z-index: 998;
  width: 80vw;
  color: white;
  opacity: 0;
  animation: speech-bubble-anim 0.5s ease 1;
  animation-fill-mode: forwards;
  animation-delay: 1200ms;
}

.intro-content a {
  pointer-events: auto;
  text-decoration: underline;
}

.nav {
  opacity: 0;
  pointer-events: none;
}

#navbar {
  opacity: 0;
  pointer-events: none;
}

.nav-list {
  display: flex;
  flex-direction: column;
  position: absolute;
  z-index: 998;
}

.list-icon i {
  font-size: 16px;
}
.list-icon span {
  opacity: 1;
  margin-left: 2px;
  width: 94px;
}
.list-icon {
  position: absolute;
  width: 128px;
  height: 32px;
  top: -16px;
  left: -32px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  background-color: #6c757d;
  border: 1px solid white;
  z-index: 998;
  opacity: 1;
}

.nav,
.nav-list,
a {
  color: white;
}

/*
.intro-content,
.about-content .title,
.timeline-point-divider-year,
.blur {
  backdrop-filter: blur(20px);
  -webkit-backdrop-filter: blur(20px);
}*/

.about-content h1 {
  display: inline-block;
  padding: 8px;
  border-radius: 8px;
}

.nav i,
.nav-list i {
  color: inherit;
  padding: 4px;
}

.nav-list .active-light {
  background-color: white !important;
  color: #6c757d;
  border-radius: 4px;
}

.nav-list .active-light {
  border-radius: 50%;
}

.social-icons {
  margin: auto;
  width: 128px;
}

.font-white {
  color: white;
}

.font-black {
  color: #6c757d;
}

.focus-circle {
  background-color: black;
}

.avatar-container {
  position: absolute;
  top: calc(60vh - 32px);
  left: 10%;
  z-index: 9999;
  width: 64px;
  height: 64px;
  display: flex;
  justify-content: center;
  align-items: center;
  visibility: hidden;
}

.avatar {
  border-radius: 50%;
  width: 0;
  height: 0;
  box-shadow: 0 0 8px 1px black;
  animation: avatar-popup 0.5s ease 1;
  animation-fill-mode: forwards;
  animation-delay: 1s;
  cursor: move;
}

@keyframes avatar-popup {
  to {
    width: 64px;
    height: 64px;
  }
}

.project-main {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}

.project-image {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  flex: 1;
}

.project-desc {
  flex: 1;
}

.carousel-control-next:hover,
.carousel-control-prev:hover {
  background-color: transparent;
}

.carousel {
  padding: 16px;
  border-radius: 8px;
  z-index: 998;
}

.carousel i {
  font-size: 32px;
}

.carousel-icon {
  color: transparent;
  padding: 100%;
}

.carousel-icon:hover {
  color: white;
}

.about-content,
.timeline-content {
  color: #39393a;
  overflow: hidden;
  z-index: 999;
}

.about-content h1,
.timeline-content h1,
.about-content p {
  color: white;
}

.about-cards {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.card {
  flex: 1;
  background-color: transparent;
  /*
  backdrop-filter: blur(20px);
  -webkit-backdrop-filter: blur(20px);*/
  border: none;
  border-radius: 8px;
}

.spacing-horizontal {
  width: 32px;
}

.card-img,
.card-img-top {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.card-img-top {
  padding: 16px 16px 0 16px;
}

.card-title {
  color: white;
}

.card-subtitle {
  color: white;
}

.card-img-container {
  height: 180px;
  background-size: cover;
  background-position: center;
}

.timeline-point {
  display: flex;
  flex-direction: row;
}

.timeline-point-image,
.timeline-point-desc {
  flex: 1;
  margin: 32px 0;
  /*backdrop-filter: blur(20px);
  -webkit-backdrop-filter: blur(20px);*/
  padding: 16px;
  border-radius: 8px;
}

.timeline-point-image {
  display: none;
  background-size: cover;
  background-repeat: no-repeat;
}

.timeline-point-desc {
  color: white;
}

.timeline-point-divider {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 2px;
  margin: 0 32px;
  flex: 1;
}

.timeline-point-divider-top,
.timeline-point-divider-bottom {
  background-color: white;
  width: 100%;
  flex: 1;
}

.timeline-point-divider-year {
  text-align: center;
  margin: 8px;
  color: white;
  font-size: 18px;
  padding: 8px;
  border-radius: 8px;
}

#avatar:hover {
  opacity: 1 !important;
}

.speech-bubble {
  position: absolute;
  left: 100px;
  top: 0;
  background: white;
  color: black;
  border-radius: 8px;
  padding: 6px;
  font-size: 20px;
  white-space: nowrap;
  opacity: 0;
  pointer-events: none;
  animation: speech-bubble-anim 0.5s ease 1;
  animation-fill-mode: forwards;
  animation-delay: 1000ms;
}

.speech-bubble:after {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  width: 0;
  height: 0;
  border: 20px solid transparent;
  border-right-color: white;
  border-left: 0;
  border-bottom: 0;
  margin-top: -10px;
  margin-left: -20px;
}

@keyframes speech-bubble-anim {
  to {
    opacity: 1;
  }
}

@media only screen and (min-width: 768px) {
  .nav {
    opacity: 1;
    pointer-events: auto;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 999;
  }

  #navbar {
    opacity: 1;
    pointer-events: auto;
    position: absolute;
    top: 0;
    overflow: hidden;
  }

  .nav-shadow {
    box-shadow: 0 0 2px 2px #6c757d;
  }

  .nav-padding {
    padding: 8px;
  }

  .nav-item {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .nav-buttons {
    width: 180px;
  }

  .nav-buttons .btn {
    color: white;
    border-color: white;
  }

  .nav-link {
    padding: 0.25rem 0.5rem;
    margin: 0.25rem 0.5rem;
  }

  .avatar-container {
    top: calc(50vh - 64px);
    left: calc(25% - 64px);
    width: 128px;
    height: 128px;
  }

  @keyframes avatar-popup {
    to {
      width: 128px;
      height: 128px;
    }
  }

  .mask circle {
    r: 100%;
    animation: spotlight 0.5s ease 1;
    animation-fill-mode: forwards;
    animation-delay: 500ms;
    cx: 25%;
    cy: 50%;
  }

  .speech-bubble {
    left: 160px;
    top: -16px;
    padding: 8px;
  }

  .intro-content {
    top: 48px;
    left: calc(25% + 128px);
    width: 50vw;
  }

  .list-icon i {
    font-size: 16px;
  }
  .list-icon {
    width: 26px;
    height: 26px;
    top: -13px;
    left: -13px;
    opacity: 0;
  }

  .nav a:hover,
  .nav-list a:hover,
  .nav i:hover,
  .nav-list i:hover,
  .nav-item .active,
  .btn:hover {
    background-color: white;
    color: #6c757d;
    border-radius: 4px;
  }

  .nav-list a:hover,
  .nav-list i:hover {
    border-radius: 50%;
  }

  .light-nav:hover,
  .nav-item .active-light {
    background-color: white;
    color: #6c757d;
    border-radius: 4px;
  }

  .list-icon span {
    display: none;
  }

  .project-main {
    flex-direction: row;
    height: 320px;
  }

  .project-image {
    height: 320px;
    margin-right: 32px;
  }

  .about-cards {
    flex-direction: row;
    margin-bottom: 32px;
  }

  .timeline-point-image {
    display: block;
  }
}
